.CardGrid {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.CardGrid--spaced {
  padding-block: var(--vkui--size_base_padding_vertical--regular);
  padding-inline: var(--vkui--size_base_padding_horizontal--regular);
}

.CardGrid--sizeX-compact {
  padding-inline: var(--vkui--size_base_padding_horizontal--regular);
}

@media (--sizeX-compact) {
  .CardGrid--sizeX-none {
    padding-inline: var(--vkui--size_base_padding_horizontal--regular);
  }
}

.CardGrid + .CardGrid {
  margin-block-start: calc(var(--vkui--size_cardgrid_padding_vertical--regular) * 2);
}

/**
 * CMP:
 * Group
 */

/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
:global(.vkuiInternalGroup--mode-card) .CardGrid {
  padding-inline: var(--vkui--size_base_padding_horizontal--regular);
}

@media (--sizeX-regular) {
  /* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
  :global(.vkuiInternalGroup--mode-none) .CardGrid {
    padding-inline: 8px;
  }
}

/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
:global(.vkuiInternalGroup) .CardGrid:first-child {
  margin-block-start: var(--vkui--size_cardgrid_padding_vertical--regular);
}

/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
:global(.vkuiInternalGroup) .CardGrid:last-child {
  margin-block-end: var(--vkui--size_cardgrid_padding_vertical--regular);
}
