.SegmentedControl {
  block-size: 30px;
  padding: 2px;
  border-radius: var(--vkui--size_border_radius--regular);
  background-color: var(--vkui--color_background_secondary);
  box-sizing: border-box;
  font-family: var(--vkui--font_family_base);
}

.SegmentedControl--size-l {
  block-size: 36px;
}

.SegmentedControl__in {
  position: relative;
  display: flex;
  block-size: 100%;
  inline-size: 100%;
  transition: all 100ms ease-out;
  border-radius: inherit;
}

.SegmentedControl__slider {
  position: absolute;
  inset-block: 0;
  inset-inline-start: 0;
  background-color: var(--vkui--color_segmented_control);
  transition: transform 150ms;
  transform-origin: 0 0;
  border-radius: inherit;
  box-shadow:
    inset 0 0 0 0.5px var(--vkui--color_image_border_alpha),
    0 3px 8px rgba(0, 0, 0, 0.12),
    0 3px 1px rgba(0, 0, 0, 0.04);
}

/**
 * sizeY REGULAR
 */
.SegmentedControl--sizeY-regular {
  block-size: 36px;
}

.SegmentedControl--sizeY-regular.SegmentedControl--size-l {
  block-size: 44px;
}

@media (--sizeY-regular) {
  .SegmentedControl--sizeY-none {
    block-size: 36px;
  }

  .SegmentedControl--sizeY-none.SegmentedControl--size-l {
    block-size: 44px;
  }
}
