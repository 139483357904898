.SelectionControl {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-inline: var(--vkui--size_base_padding_horizontal--regular);
  font-family: var(--vkui--font_family_base);
  min-block-size: var(--vkui--size_field_height--regular);
  box-sizing: border-box;
}

/**
 * CMP:
 * FormItem
 */
/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
:global(.vkuiInternalFormItem) .SelectionControl {
  margin-block: 0;
  margin-inline: calc(-1 * var(--vkui--size_base_padding_horizontal--regular));
}

.SelectionControl[disabled] {
  opacity: var(--vkui--opacity_disable_accessibility);
}

.SelectionControl--sizeY-compact {
  min-block-size: var(--vkui--size_field_height--compact);
}

@media (--sizeY-compact) {
  .SelectionControl--sizeY-none {
    min-block-size: var(--vkui--size_field_height--compact);
  }
}
