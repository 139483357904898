.-anim-fade-in {
  animation: anim-fade-in 0.1s ease-in forwards;
}

.-anim-fade-out {
  animation: anim-fade-out 0.1s ease-out forwards;
}

@keyframes anim-fade-in {
  from {
    opacity: 0;
    transform: scale(0.8);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes anim-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
