.ImageBadge {
  position: absolute;
  inset-block-end: -4px;
  inset-inline-end: -4px;
}

.ImageBadge--shifted {
  inset-block-end: -3px;
  inset-inline-end: -3px;
}
