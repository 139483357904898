.Input {
  position: relative;
  line-height: 20px;
  font-size: 16px;
}

.Input--align-center .Input__el {
  text-align: center;
}

.Input--align-right .Input__el {
  text-align: end;
}

.Input__el {
  position: relative;
  z-index: var(--vkui_internal--z_index_form_field_element);
  padding-block: 0;
  padding-inline: 12px;
  inline-size: 100%;
  block-size: var(--vkui--size_field_height--regular);
  box-sizing: border-box;
  border-radius: inherit;
  color: var(--vkui--color_text_primary);
}

.Input--sizeY-compact .Input__el {
  block-size: var(--vkui--size_field_height--compact);
}

@media (--sizeY-compact) {
  .Input--sizeY-none .Input__el {
    block-size: var(--vkui--size_field_height--compact);
  }
}

.Input--hasBefore .Input__el {
  padding-inline-start: 0;
}

.Input--hasAfter .Input__el {
  padding-inline-end: 0;
}

.Input__el:disabled {
  opacity: var(--vkui--opacity_disable_accessibility);
}

.Input__el::placeholder {
  color: var(--vkui--color_text_secondary);
  /* Для Firefox */
  opacity: 1;
}

.Input__el:disabled::placeholder {
  color: var(--vkui--color_text_secondary);
}
