.CheckboxSimple {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  inline-size: var(--vkui--size_field_height--regular);
  block-size: var(--vkui--size_field_height--regular);
}

.CheckboxSimple[disabled] {
  opacity: var(--vkui--opacity_disable_accessibility);
}

/**
 * sizeY COMPACT
 */

.CheckboxSimple--sizeY-compact {
  inline-size: var(--vkui--size_field_height--compact);
  block-size: var(--vkui--size_field_height--compact);
}

@media (--sizeY-compact) {
  .CheckboxSimple--sizeY-none {
    inline-size: var(--vkui--size_field_height--compact);
    block-size: var(--vkui--size_field_height--compact);
  }
}
