/* stylelint-disable selector-max-universal */
.CellDragger {
  cursor: ns-resize;
  color: var(--vkui--color_icon_secondary);
  user-select: none;
  touch-action: manipulation;
}

.CellDragger__icon {
  pointer-events: none;
}
