.RadioInput__icon {
  display: flex;
  flex-shrink: 0;
  color: var(--vkui--color_icon_tertiary);
  transition: color 0.15s var(--vkui--animation_easing_platform);
}

.RadioInput__input:checked ~ .RadioInput__icon {
  color: var(--vkui--color_icon_accent);
}

.RadioInput__icon .RadioInput__pin {
  transform-origin: center;
  transform: scale(0);
  transition: transform 0.15s var(--vkui--animation_easing_platform);
}

.RadioInput__input:checked ~ .RadioInput__icon .RadioInput__pin {
  transform: none;
}
