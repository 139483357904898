.Footnote {
  font-size: var(--vkui--font_footnote--font_size--regular);
  line-height: var(--vkui--font_footnote--line_height--regular);
  font-weight: var(--vkui--font_footnote--font_weight--regular);
  font-family: var(--vkui--font_footnote--font_family--regular);
}

.Footnote--sizeY-compact {
  font-size: var(
    --vkui--font_footnote--font_size--compact,
    var(--vkui--font_footnote--font_size--regular)
  );
  line-height: var(
    --vkui--font_footnote--line_height--compact,
    var(--vkui--font_footnote--line_height--regular)
  );
  font-weight: var(
    --vkui--font_footnote--font_weight--compact,
    var(--vkui--font_footnote--font_weight--regular)
  );
  font-family: var(
    --vkui--font_footnote--font_family--compact,
    var(--vkui--font_footnote--font_family--regular)
  );
}

@media (--sizeY-compact) {
  .Footnote--sizeY-none {
    font-size: var(
      --vkui--font_footnote--font_size--compact,
      var(--vkui--font_footnote--font_size--regular)
    );
    line-height: var(
      --vkui--font_footnote--line_height--compact,
      var(--vkui--font_footnote--line_height--regular)
    );
    font-weight: var(
      --vkui--font_footnote--font_weight--compact,
      var(--vkui--font_footnote--font_weight--regular)
    );
    font-family: var(
      --vkui--font_footnote--font_family--compact,
      var(--vkui--font_footnote--font_family--regular)
    );
  }
}

.Footnote--caps {
  font-size: var(--vkui--font_footnote_caps--font_size--regular);
  line-height: var(--vkui--font_footnote_caps--line_height--regular);
  font-weight: var(--vkui--font_footnote_caps--font_weight--regular);
  font-family: var(--vkui--font_footnote_caps--font_family--regular);
  text-transform: uppercase;
}

.Footnote--sizeY-compact.Footnote--caps {
  font-size: var(
    --vkui--font_footnote_caps--font_size--compact,
    var(--vkui--font_footnote_caps--font_size--regular)
  );
  line-height: var(
    --vkui--font_footnote_caps--line_height--compact,
    var(--vkui--font_footnote_caps--line_height--regular)
  );
  font-weight: var(
    --vkui--font_footnote_caps--font_weight--compact,
    var(--vkui--font_footnote_caps--font_weight--regular)
  );
  font-family: var(
    --vkui--font_footnote_caps--font_family--compact,
    var(--vkui--font_footnote_caps--font_family--regular)
  );
}

@media (--sizeY-compact) {
  .Footnote--sizeY-none.Footnote--caps {
    font-size: var(
      --vkui--font_footnote_caps--font_size--compact,
      var(--vkui--font_footnote_caps--font_size--regular)
    );
    line-height: var(
      --vkui--font_footnote_caps--line_height--compact,
      var(--vkui--font_footnote_caps--line_height--regular)
    );
    font-weight: var(
      --vkui--font_footnote_caps--font_weight--compact,
      var(--vkui--font_footnote_caps--font_weight--regular)
    );
    font-family: var(
      --vkui--font_footnote_caps--font_family--compact,
      var(--vkui--font_footnote_caps--font_family--regular)
    );
  }
}
