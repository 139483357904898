.ModalPageHeader {
  --vkui_internal--safe_area_inset_top: 0;
}

.ModalPageHeader--withGaps {
  padding-inline: 4px;
}

.ModalPageHeader--desktop.ModalPageHeader--withGaps {
  padding-inline: 8px;
}

/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
.ModalPageHeader :global(.vkuiIcon--dismiss_24), /* Note: <Icon24Dismiss /> по документации используется только для iOS  */
.ModalPageHeader :global(.vkuiIcon--cancel_24) {
  color: var(--vkui--color_icon_secondary);
}
