.AvatarBadge {
  inset-inline-end: 0;
  inset-block-end: 0;
}

.AvatarBadge--shifted {
  inset-inline-end: -2px;
  inset-block-end: -2px;
}

.AvatarBadge--preset-online,
.AvatarBadge--preset-onlineMobile {
  inset-inline-end: 14%;
  inset-block-end: 14%;
  color: var(--vkui--color_accent_green);
  transform: translate(50%, 50%);
}

/**
 * Перебиваем свойства `ImageBaseBadge` для этих кейсов
 */
.AvatarBadge--preset-online {
  padding: 0;
}

.AvatarBadge--preset-onlineMobile {
  border-radius: 3px;
}
