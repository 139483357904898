/* Row Gap */

.-row-gap--2xs {
  --vkui_internal--row_gap: var(--vkui--spacing_size_2xs);
}

.-row-gap--xs {
  --vkui_internal--row_gap: var(--vkui--spacing_size_xs);
}

.-row-gap--s {
  --vkui_internal--row_gap: var(--vkui--spacing_size_s);
}

.-row-gap--m {
  --vkui_internal--row_gap: var(--vkui--spacing_size_m);
}

.-row-gap--l {
  --vkui_internal--row_gap: var(--vkui--spacing_size_l);
}

.-row-gap--xl {
  --vkui_internal--row_gap: var(--vkui--spacing_size_xl);
}

.-row-gap--2xl {
  --vkui_internal--row_gap: var(--vkui--spacing_size_2xl);
}

.-row-gap--3xl {
  --vkui_internal--row_gap: var(--vkui--spacing_size_3xl);
}

.-row-gap--4xl {
  --vkui_internal--row_gap: var(--vkui--spacing_size_4xl);
}

/* Column Gap */

.-column-gap--2xs {
  --vkui_internal--column_gap: var(--vkui--spacing_size_2xs);
}

.-column-gap--xs {
  --vkui_internal--column_gap: var(--vkui--spacing_size_xs);
}

.-column-gap--s {
  --vkui_internal--column_gap: var(--vkui--spacing_size_s);
}

.-column-gap--m {
  --vkui_internal--column_gap: var(--vkui--spacing_size_m);
}

.-column-gap--l {
  --vkui_internal--column_gap: var(--vkui--spacing_size_l);
}

.-column-gap--xl {
  --vkui_internal--column_gap: var(--vkui--spacing_size_xl);
}

.-column-gap--2xl {
  --vkui_internal--column_gap: var(--vkui--spacing_size_2xl);
}

.-column-gap--3xl {
  --vkui_internal--column_gap: var(--vkui--spacing_size_3xl);
}

.-column-gap--4xl {
  --vkui_internal--column_gap: var(--vkui--spacing_size_4xl);
}
