.TooltipBase {
  white-space: normal;
}

.TooltipBase__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--vkui--color_background_contrast_themed);
  box-shadow: var(--vkui--elevation3);
  padding-block: 9px 10px;
  padding-inline: 12px;
  border-radius: var(--vkui--size_border_radius--regular);
  color: var(--vkui--color_text_primary);
}

.TooltipBase__closeButton {
  flex-grow: 0;
  flex-shrink: 0;
  align-self: flex-start;
  margin-inline-start: 10px;
  color: inherit;
  padding: 0;
  border: 0;
  background: none;
}

.TooltipBase__arrow {
  color: var(--vkui--color_background_contrast_themed);
}

.TooltipBase--appearance-accent .TooltipBase__content {
  background-color: var(--vkui--color_background_accent_tint);
  color: var(--vkui--color_text_contrast);
}

.TooltipBase--appearance-accent .TooltipBase__arrow {
  color: var(--vkui--color_background_accent_tint);
}

.TooltipBase--appearance-white .TooltipBase__content {
  background-color: var(--vkui--color_background_contrast);
  color: var(--vkui--color_text_primary_invariably);
}

.TooltipBase--appearance-white .TooltipBase__arrow {
  color: var(--vkui--color_background_contrast);
}

.TooltipBase--appearance-black .TooltipBase__content {
  background-color: var(--vkui--color_background_contrast_inverse);
  color: var(--vkui--color_text_contrast);
}

.TooltipBase--appearance-black .TooltipBase__arrow {
  color: var(--vkui--color_background_contrast_inverse);
}

.TooltipBase--appearance-inversion .TooltipBase__content {
  background-color: var(--vkui--color_background_modal_inverse);
  color: var(--vkui--color_text_contrast_themed);
}

.TooltipBase--appearance-inversion .TooltipBase__arrow {
  color: var(--vkui--color_background_modal_inverse);
}
