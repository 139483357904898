.DatePicker {
  margin: 0;
  padding: 0;
  border: 0;
}

.DatePicker__container {
  inline-size: 100%;
  display: flex;
  flex-direction: row;
}

.DatePicker__day {
  min-inline-size: 72px;
}

.DatePicker__month {
  min-inline-size: 0;
  display: flex;
  flex: 1 0 0;
  padding-block: 0;
  padding-inline: 8px;
}

.DatePicker__year {
  min-inline-size: 92px;
}

.DatePicker__monthSelect {
  inline-size: 100%;
}
