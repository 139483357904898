.PopoutRoot {
  inline-size: 100%;
  block-size: 100%;
  position: relative;
}

.PopoutRoot__popout {
  position: fixed;
  inset-inline-start: 0;
  inset-block-start: 0;
  z-index: var(--vkui--z_index_popout);
  inline-size: 100%;
  block-size: 100%;
}

.PopoutRoot__modal {
  position: fixed;
  inset-inline-start: 0;
  inset-block-start: 0;
  z-index: var(--vkui--z_index_modal);
  inline-size: 100%;
  block-size: 100%;
}

.PopoutRoot__popout:empty,
.PopoutRoot__modal:empty {
  display: none;
}
