.Root {
  --vkui_internal--root_animation_opacity_initial: 0;
  --vkui_internal--root_animation_transform_initial: scale(1.05);
  --vkui_internal--root_animation_duration: 0.3s;

  inline-size: 100%;
  block-size: 100%;
  position: relative;
}

.Root--ios {
  --vkui_internal--root_animation_opacity_initial: 1;
  --vkui_internal--root_animation_transform_initial: translate3d(0, 100%, 0);
  --vkui_internal--root_animation_duration: 0.6s;
}

.Root--transition {
  pointer-events: none;
}

.Root__view {
  inline-size: 100%;
  block-size: 100%;
  position: relative;
}

.Root__view--show-forward {
  /* prettier-ignore */
  animation: root-animation-show-forward var(--vkui_internal--root_animation_duration) var(--vkui--animation_easing_platform);
}

.Root__view--hide-back {
  /* prettier-ignore */
  animation: root-animation-hide-back var(--vkui_internal--root_animation_duration) var(--vkui--animation_easing_platform) forwards;
}

.Root--transition .Root__view {
  position: fixed;
  inset-inline-start: 0;
  inset-block-start: 0;
  overflow: hidden;
}

.Root__scrollCompensation {
  inline-size: 100%;
  block-size: 100%;
}

/**
 * iOS
 */

.Root--ios .Root__view--show-back::after,
.Root--ios .Root__view--hide-forward::after {
  position: absolute;
  inline-size: 100%;
  block-size: 100%;
  inset-inline-start: 0;
  inset-block-start: 0;
  display: block;
  content: '';
  background-color: #000;
}

.Root--ios .Root__view--show-back::after {
  /* prettier-ignore */
  animation: root-ios-overlay-animation-show-back var(--vkui_internal--root_animation_duration) var(--vkui--animation_easing_platform) forwards;
}

.Root--ios .Root__view--hide-forward::after {
  /* prettier-ignore */
  animation: root-ios-overlay-animation-hide-forward var(--vkui_internal--root_animation_duration) var(--vkui--animation_easing_platform);
}

@media (--reduce-motion) {
  .Root {
    --vkui_internal--root_animation_transform_initial: 1;
  }

  .Root--ios {
    --vkui_internal--root_animation_opacity_initial: 0;
    --vkui_internal--root_animation_transform_initial: 1;
  }
}

@keyframes root-animation-hide-back {
  from {
    transform: none;
    opacity: 1;
  }

  to {
    transform: var(--vkui_internal--root_animation_transform_initial);
    opacity: var(--vkui_internal--root_animation_opacity_initial);
  }
}
@keyframes root-animation-show-forward {
  from {
    transform: var(--vkui_internal--root_animation_transform_initial);
    opacity: var(--vkui_internal--root_animation_opacity_initial);
  }

  to {
    transform: none;
    opacity: 1;
  }
}
@keyframes root-ios-overlay-animation-show-back {
  from {
    opacity: 0.3;
  }

  to {
    opacity: 0;
  }
}
@keyframes root-ios-overlay-animation-hide-forward {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.3;
  }
}
