.-anim-transform-origin-top {
  transform-origin: bottom center;
}

.-anim-transform-origin-top-start {
  transform-origin: bottom left;
}

.-anim-transform-origin-top-end {
  transform-origin: bottom right;
}

.-anim-transform-origin-right {
  transform-origin: left center;
}

.-anim-transform-origin-right-start {
  transform-origin: left top;
}

.-anim-transform-origin-right-end {
  transform-origin: left bottom;
}

.-anim-transform-origin-bottom {
  transform-origin: top center;
}

.-anim-transform-origin-bottom-start {
  transform-origin: top left;
}

.-anim-transform-origin-bottom-end {
  transform-origin: top right;
}

.-anim-transform-origin-left {
  transform-origin: right center;
}

.-anim-transform-origin-left-start {
  transform-origin: right top;
}

.-anim-transform-origin-left-end {
  transform-origin: right bottom;
}
