.InputLike__mask {
  position: relative;
  font-family: var(--vkui--font_family_base);
}

.InputLike__mask::after {
  content: '';
  position: absolute;
  inline-size: calc(100% - 0.1em);
  block-size: 1px;
  inset-block-end: 0;
  inset-inline-start: 0;
  border-block-end: solid 1px var(--vkui--color_text_secondary);
}

.InputLike__mask:last-child {
  margin-inline-end: 1px;
}

.InputLike__last_character {
  /* stylelint-disable-next-line declaration-no-important */
  letter-spacing: 1px !important;
}

.InputLike__divider {
  color: var(--vkui--color_text_secondary);
  /* stylelint-disable-next-line declaration-no-important */
  letter-spacing: 1px !important;
}

.InputLike--full + .InputLike__divider {
  color: var(--vkui--color_text_primary);
}
