.Snackbar__body {
  --vkui_internal--snackbar-body_layout_vertical_action_shift: calc(
    -1 * (
        var(--vkui--size_button_small_height--compact) -
          var(--vkui--font_subhead--line_height--compact)
      ) / 2
  );

  box-sizing: border-box;
  padding: 12px;
  min-block-size: 56px;
  display: flex;
  align-items: center;
  background: var(--vkui--color_background_contrast_themed);
  box-shadow: var(--vkui--elevation4);
  border-radius: var(--vkui--size_card_border_radius--regular);
}

.Snackbar--sizeY-regular {
  --vkui_internal--snackbar-body_layout_vertical_action_shift: calc(
    -1 * (
        var(--vkui--size_button_small_height--regular) -
          var(--vkui--font_subhead--line_height--regular)
      ) / 2
  );
}

@media (--sizeY-regular) {
  .Snackbar--sizeY-none {
    --vkui_internal--snackbar-body_layout_vertical_action_shift: calc(
      -1 * (
          var(--vkui--size_button_small_height--regular) -
            var(--vkui--font_subhead--line_height--regular)
        ) / 2
    );
  }
}

.Snackbar--mode-dark {
  background: var(--vkui--color_background_contrast_inverse);
}

.Snackbar__before {
  margin-inline-end: 12px;
}

.Snackbar__after {
  margin-inline-start: 12px;
}

.Snackbar__content {
  flex: 1;
  display: flex;
  align-items: center;
}

.Snackbar__content-text {
  flex: 1;
  color: var(--vkui--color_text_primary);
  inline-size: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.Snackbar__content-subtitle {
  color: var(--vkui--color_text_subhead);
  margin-block-start: 2px;
}

.Snackbar--mode-dark .Snackbar__content-text {
  color: var(--vkui--color_text_contrast);
}

/* layout */
.Snackbar--layout-horizontal .Snackbar__action {
  position: relative;
  margin-inline-start: 12px;
}

.Snackbar--layout-vertical .Snackbar__content {
  flex-direction: column;
  align-items: flex-start;
}

.Snackbar--layout-vertical .Snackbar__action {
  min-block-size: initial;
  margin-block-start: 2px;
  margin-block-end: var(--vkui_internal--snackbar-body_layout_vertical_action_shift);
}

@media (--viewWidth-smallTabletMinus) {
  .Snackbar--layout-none .Snackbar__action {
    position: relative;
    margin-inline-start: 12px;
  }
}
@media (--viewWidth-smallTabletPlus) {
  .Snackbar--layout-none .Snackbar__content,
  .Snackbar--layout-vertical-mq .Snackbar__content {
    flex-direction: column;
    align-items: flex-start;
  }

  .Snackbar--layout-none .Snackbar__action,
  .Snackbar--layout-vertical-mq .Snackbar__action {
    min-block-size: initial;
    margin-block-start: 2px;
    margin-block-end: var(--vkui_internal--snackbar-body_layout_vertical_action_shift);
  }
}
