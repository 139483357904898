.FormItem {
  display: block;
}

.FormItem--withPadding {
  padding-block: var(--vkui--size_form_item_padding_vertical--regular);
  padding-inline: var(--vkui--size_base_padding_horizontal--regular);
}

.FormItem--removable {
  padding-inline: 0;
}

.FormItem__removable {
  max-inline-size: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  padding: 0;
  min-inline-size: 1px;
  overflow: hidden;
}

.FormItem__top {
  padding-block: 2px 8px;
  color: var(--vkui--color_text_subhead);
  display: flex;
  align-items: flex-end;
}

.FormItemTop__label,
.FormItemTop__aside {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.FormItemTop__label {
  flex: 1;
}

.FormItemTop__aside {
  color: var(--vkui--color_text_tertiary);
  margin-inline-start: auto;
}

.FormItemTop__aside:not(:only-child) {
  padding-inline-start: 12px;
}

.FormItemTop__label--multiline {
  white-space: normal;
}

.FormItemTop__label--required {
  color: var(--vkui--color_text_negative);
  margin-inline-start: 3px;
}

.FormItem__bottom {
  color: var(--vkui--color_text_secondary);
  padding-block-start: 8px;
}

.FormItem--status-error .FormItem__bottom {
  color: var(--vkui--color_text_negative);
}

.FormItem--status-valid .FormItem__bottom,
.FormItem--verified .FormItem__bottom {
  color: var(--vkui--color_text_positive);
}

/**
 * sizeY COMPACT
 */

.FormItem--sizeY-compact .FormItem__top {
  padding-block-end: 6px;
}

.FormItem--sizeY-compact .FormItem__bottom {
  padding-block-start: 6px;
}

@media (--sizeY-compact) {
  .FormItem--sizeY-none .FormItem__top {
    padding-block-end: 6px;
  }

  .FormItem--sizeY-none .FormItem__bottom {
    padding-block-start: 6px;
  }
}

/**
 * CMP:
 * FormLayoutGroup
 */
/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
:global(.vkuiInternalFormLayoutGroup--mode-horizontal) .FormItem {
  max-inline-size: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  padding: 0;
  min-inline-size: 1px;
}

/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
:global(.vkuiInternalFormLayoutGroup--mode-horizontal:not(.vkuiInternalFormLayoutGroup--segmented))
  .FormItem
  + .FormItem {
  margin-inline-start: 16px;
}

/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
:global(.vkuiInternalFormLayoutGroup--mode-horizontal.vkuiInternalFormLayoutGroup--segmented)
  .FormItem
  + .FormItem {
  margin-inline-start: calc(-1 * var(--vkui--size_border--regular));
}

/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
:global(.vkuiInternalFormLayoutGroup--mode-vertical.vkuiInternalFormLayoutGroup--segmented)
  .FormItem
  + .FormItem {
  padding-block-start: 0;
  margin-block-start: calc(-1 * var(--vkui--size_border--regular));
}

/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
:global(.vkuiInternalFormLayoutGroup--mode-vertical.vkuiInternalFormLayoutGroup--segmented)
  .FormItem:not(:last-of-type) {
  padding-block-end: 0;
}

/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
.FormItem--withTop .FormItem__removable,
:global(.vkuiInternalFormLayoutGroup--mode-horizontal) .FormItem--withTop {
  margin-block-start: calc(-2px - var(--vkui--font_subhead--line_height--regular) - 8px);
}

/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
.FormItem--sizeY-compact.FormItem--withTop .FormItem__removable,
:global(.vkuiInternalFormLayoutGroup--mode-horizontal) .FormItem--sizeY-compact.FormItem--withTop {
  margin-block-start: calc(-2px - var(--vkui--font_subhead--line_height--compact) - 6px);
}

@media (--sizeY-compact) {
  /* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
  .FormItem--sizeY-none.FormItem--withTop .FormItem__removable,
  :global(.vkuiInternalFormLayoutGroup--mode-horizontal) .FormItem--sizeY-none.FormItem--withTop {
    margin-block-start: calc(-2px - var(--vkui--font_subhead--line_height--compact) - 6px);
  }
}
