.ContentCard--disabled {
  opacity: var(--vkui--opacity_disable_accessibility);
}

.ContentCard__body {
  padding-block: var(--vkui--size_base_padding_vertical--regular);
  padding-inline: var(--vkui--size_base_padding_horizontal--regular);
}

.ContentCard__tappable {
  border-radius: var(--vkui--size_card_border_radius--regular);
  text-decoration: none;
  color: var(--vkui--color_text_primary);
  display: block;
}

.ContentCard__img {
  object-fit: cover;
  display: block;
  border-start-start-radius: var(--vkui--size_card_border_radius--regular);
  border-start-end-radius: var(--vkui--size_card_border_radius--regular);
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}

.ContentCard__text:not(:last-child) {
  margin-block-end: 4px;
}

.ContentCard__caption,
.ContentCard__subtitle {
  color: var(--vkui--color_text_secondary);
}
