.SimpleGrid {
  --vkui_internal--grid_columns: 1;
  --vkui_internal--min_col_width: 0;

  display: grid;
  gap: var(--vkui_internal--row_gap) var(--vkui_internal--column_gap);
  grid-template-columns: repeat(var(--vkui_internal--grid_columns), minmax(0, 1fr));
}

.SimpleGrid--margin-auto {
  margin-inline: var(--vkui--size_base_padding_horizontal--regular);
  margin-block: var(--vkui--size_base_padding_vertical--regular);
}

.SimpleGrid--margin-auto-inline {
  margin-inline: var(--vkui--size_base_padding_horizontal--regular);
}

.SimpleGrid--margin-auto-block {
  margin-block: var(--vkui--size_base_padding_vertical--regular);
}

.SimpleGrid--with-min-width {
  grid-template-columns: repeat(auto-fit, minmax(var(--vkui_internal--min_col_width), 1fr));
}

.SimpleGrid--align-start {
  align-items: flex-start;
}

.SimpleGrid--align-end {
  align-items: flex-end;
}

.SimpleGrid--align-center {
  align-items: center;
}

.SimpleGrid--align-stretch {
  align-items: stretch;
}

.SimpleGrid--align-baseline {
  align-items: baseline;
}
