.Subhead {
  font-size: var(--vkui--font_subhead--font_size--regular);
  line-height: var(--vkui--font_subhead--line_height--regular);
  font-weight: var(--vkui--font_subhead--font_weight--regular);
  font-family: var(--vkui--font_subhead--font_family--regular);
}

.Subhead--sizeY-compact {
  font-size: var(
    --vkui--font_subhead--font_size--compact,
    var(--vkui--font_subhead--font_size--regular)
  );
  line-height: var(
    --vkui--font_subhead--line_height--compact,
    var(--vkui--font_subhead--line_height--regular)
  );
  font-weight: var(
    --vkui--font_subhead--font_weight--compact,
    var(--vkui--font_subhead--font_weight--regular)
  );
  font-family: var(
    --vkui--font_subhead--font_family--compact,
    var(--vkui--font_subhead--font_family--regular)
  );
}

@media (--sizeY-compact) {
  .Subhead--sizeY-none {
    font-size: var(
      --vkui--font_subhead--font_size--compact,
      var(--vkui--font_subhead--font_size--regular)
    );
    line-height: var(
      --vkui--font_subhead--line_height--compact,
      var(--vkui--font_subhead--line_height--regular)
    );
    font-weight: var(
      --vkui--font_subhead--font_weight--compact,
      var(--vkui--font_subhead--font_weight--regular)
    );
    font-family: var(
      --vkui--font_subhead--font_family--compact,
      var(--vkui--font_subhead--font_family--regular)
    );
  }
}
