.FlexItem {
  /* Пустой класс для CSS Modules (см. CONTRIBUTING.md)  */
}

/* flex */

.FlexItem--flex-grow {
  flex: 1 0 auto;
}

.FlexItem--flex-shrink {
  flex: 0 1 auto;
}

.FlexItem--flex-content {
  flex: 1 1 auto;
}

.FlexItem--flex-fixed {
  flex: 0 0 auto;
}

/* align-self */

.FlexItem--align-self-start {
  align-self: start;
}

.FlexItem--align-self-end {
  align-self: end;
}

.FlexItem--align-self-center {
  align-self: center;
}

.FlexItem--align-self-baseline {
  align-self: baseline;
}

.FlexItem--align-self-stretch {
  align-self: stretch;
}
