.Spinner {
  inline-size: 100%;
  block-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--vkui--color_icon_medium);
}

.Spinner--no-color {
  color: currentColor;
}

/**
 * CMP:
 * PanelHeader
 * TODO [>=7]: удалить
 */
/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
:global(.vkuiInternalPanelHeader) .Spinner {
  color: currentColor;
}
