.ChipsSelect {
  position: relative;
}

.ChipsSelect__empty {
  padding-block: 12px;
  padding-inline: 0;
  text-align: center;
  color: var(--vkui--color_text_secondary);
}

.ChipsSelect--pop-down {
  border-end-start-radius: 0;
  border-end-end-radius: 0;
}

.ChipsSelect--pop-up {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
}

.ChipsSelect__dropdown-icon {
  cursor: pointer;
}

.ChipsSelect__dropdown-icon--withOffset {
  margin-inline-end: 8px;
}
