.DropZone {
  position: relative;
}

.DropZone::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: var(--vkui--size_border_radius--regular);
  border: 1px dashed var(--vkui--color_separator_primary);
}

.DropZone--active::before {
  border-color: var(--vkui--color_stroke_accent_themed);
  border-width: 2px;
}
