.Cell {
  position: relative;
}

.Cell--dragging {
  background-color: var(--vkui--color_background_secondary);
  box-shadow: var(--vkui--elevation3);
}

.Cell__checkbox,
.Cell__dragger {
  position: relative;
  flex-shrink: 0;
  margin-inline-end: var(--vkui--spacing_size_2xl);
}

.Cell__control--noBefore {
  /* Когда в before нет ничего, кроме dragger или checkbox, нужно добавлять компенсированный отступ */
  margin-inline-end: calc(var(--vkui--spacing_size_2xl) - var(--vkui--spacing_size_xl));
}

.Cell__content {
  max-inline-size: 100%;
  min-inline-size: 0;
}

.Cell--removable .Cell__content {
  flex-grow: 1;
  /* размер removable icon */
  padding-inline-end: 44px;
}

/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
.Cell--removable :global(.vkuiInternalRemovable__content) {
  padding-inline-start: 0;
}

/* stylelint-disable-next-line selector-pseudo-class-disallowed-list -- для того чтобы активная область Cell распространялась и на область removable */
.Cell--removable :global(.vkuiInternalRemovable__action) {
  position: absolute;
  inset-inline-end: 0;
  padding-inline-start: 0;
}

/**
 * iOS
 */
.Cell--ios .Cell__dragger {
  margin-inline: var(--vkui--spacing_size_xl) 0;
}

.Cell--ios .Cell__checkbox {
  margin-inline-end: var(--vkui--spacing_size_xl);
}

.Cell--ios .Cell__control--noBefore {
  /* Когда в before нет ничего, кроме dragger или checkbox, оставляем только отступ у before в SimpleCell */
  margin-inline-end: 0;
}

.Cell--ios.Cell--removable .Cell__content {
  /* размер removable icon + icon margins*/
  padding-inline: 48px var(--vkui--size_base_padding_horizontal--regular);
  /* правый паддинг от removable который мы обнулили ниже */
}

/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
.Cell--ios.Cell--removable :global(.vkuiInternalRemovable__action) {
  inset-inline: 0 auto;
  z-index: 1;
}

/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
.Cell--ios.Cell--removable :global(.vkuiInternalRemovable__content) {
  padding-inline-end: 0;
}

/**
 * CMP:
 * FormItem
 */
/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
:global(.vkuiInternalFormItem) > .Cell {
  margin-block: 0;
  margin-inline: calc(-1 * var(--vkui--size_base_padding_horizontal--regular));
}
