.Title--level-1 {
  font-size: var(--vkui--font_title1--font_size--regular);
  line-height: var(--vkui--font_title1--line_height--regular);
  font-weight: var(--vkui--font_title1--font_weight--regular);
  font-family: var(--vkui--font_title1--font_family--regular);
}

.Title--sizeY-compact.Title--level-1 {
  font-size: var(
    --vkui--font_title1--font_size--compact,
    var(--vkui--font_title1--font_size--regular)
  );
  line-height: var(
    --vkui--font_title1--line_height--compact,
    var(--vkui--font_title1--line_height--regular)
  );
  font-weight: var(
    --vkui--font_title1--font_weight--compact,
    var(--vkui--font_title1--font_weight--regular)
  );
  font-family: var(
    --vkui--font_title1--font_family--compact,
    var(--vkui--font_title1--font_family--regular)
  );
}

@media (--sizeY-compact) {
  .Title--sizeY-none.Title--level-1 {
    font-size: var(
      --vkui--font_title1--font_size--compact,
      var(--vkui--font_title1--font_size--regular)
    );
    line-height: var(
      --vkui--font_title1--line_height--compact,
      var(--vkui--font_title1--line_height--regular)
    );
    font-weight: var(
      --vkui--font_title1--font_weight--compact,
      var(--vkui--font_title1--font_weight--regular)
    );
    font-family: var(
      --vkui--font_title1--font_family--compact,
      var(--vkui--font_title1--font_family--regular)
    );
  }
}

.Title--level-2 {
  font-size: var(--vkui--font_title2--font_size--regular);
  line-height: var(--vkui--font_title2--line_height--regular);
  font-weight: var(--vkui--font_title2--font_weight--regular);
  font-family: var(--vkui--font_title2--font_family--regular);
}

.Title--sizeY-compact.Title--level-2 {
  font-size: var(
    --vkui--font_title2--font_size--compact,
    var(--vkui--font_title2--font_size--regular)
  );
  line-height: var(
    --vkui--font_title2--line_height--compact,
    var(--vkui--font_title2--line_height--regular)
  );
  font-weight: var(
    --vkui--font_title2--font_weight--compact,
    var(--vkui--font_title2--font_weight--regular)
  );
  font-family: var(
    --vkui--font_title2--font_family--compact,
    var(--vkui--font_title2--font_family--regular)
  );
}

@media (--sizeY-compact) {
  .Title--sizeY-none.Title--level-2 {
    font-size: var(
      --vkui--font_title2--font_size--compact,
      var(--vkui--font_title2--font_size--regular)
    );
    line-height: var(
      --vkui--font_title2--line_height--compact,
      var(--vkui--font_title2--line_height--regular)
    );
    font-weight: var(
      --vkui--font_title2--font_weight--compact,
      var(--vkui--font_title2--font_weight--regular)
    );
    font-family: var(
      --vkui--font_title2--font_family--compact,
      var(--vkui--font_title2--font_family--regular)
    );
  }
}

.Title--level-3 {
  font-size: var(--vkui--font_title3--font_size--regular);
  line-height: var(--vkui--font_title3--line_height--regular);
  font-weight: var(--vkui--font_title3--font_weight--regular);
  font-family: var(--vkui--font_title3--font_family--regular);
}

.Title--sizeY-compact.Title--level-3 {
  font-size: var(
    --vkui--font_title3--font_size--compact,
    var(--vkui--font_title3--font_size--regular)
  );
  line-height: var(
    --vkui--font_title3--line_height--compact,
    var(--vkui--font_title3--line_height--regular)
  );
  font-weight: var(
    --vkui--font_title3--font_weight--compact,
    var(--vkui--font_title3--font_weight--regular)
  );
  font-family: var(
    --vkui--font_title3--font_family--compact,
    var(--vkui--font_title3--font_family--regular)
  );
}

@media (--sizeY-compact) {
  .Title--sizeY-none.Title--level-3 {
    font-size: var(
      --vkui--font_title3--font_size--compact,
      var(--vkui--font_title3--font_size--regular)
    );
    line-height: var(
      --vkui--font_title3--line_height--compact,
      var(--vkui--font_title3--line_height--regular)
    );
    font-weight: var(
      --vkui--font_title3--font_weight--compact,
      var(--vkui--font_title3--font_weight--regular)
    );
    font-family: var(
      --vkui--font_title3--font_family--compact,
      var(--vkui--font_title3--font_family--regular)
    );
  }
}
