.Clickable__realClickable {
  cursor: pointer;
}

.Clickable__resetButtonStyle {
  appearance: none;
  user-select: none;
  -webkit-tap-highlight-color: none;
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
}

.Clickable__resetLinkStyle {
  appearance: none;
  -webkit-tap-highlight-color: none;
  text-decoration: none;
}

.Clickable__host:focus,
.Clickable__host:focus-visible {
  outline: none;
}

.Clickable__host[disabled],
.Clickable__host[aria-disabled='true'] {
  cursor: not-allowed;
}
