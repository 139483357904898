.CalendarDay {
  border-radius: 0;
  font-size: 14px;
  color: var(--vkui--color_text_primary);
  font-family: var(--vkui--font_family_base);
}

.CalendarDay__hidden,
.CalendarDay {
  block-size: 36px;
  flex-basis: calc(100% / 7);
  flex-grow: 0;
}

.CalendarDay__hidden--size-s,
.CalendarDay--size-s {
  block-size: 34px;
}

.CalendarDay--disabled,
.CalendarDay--not-same-month {
  color: var(--vkui--color_text_tertiary);
}

.CalendarDay--selected {
  background-color: var(--vkui--color_background_secondary);
}

.CalendarDay--selection-start.CalendarDay--selected,
.CalendarDay__hinted--selection-start {
  border-start-start-radius: 8px;
  border-end-start-radius: 8px;
}

.CalendarDay--selection-end.CalendarDay--selected,
.CalendarDay__hinted--selection-end {
  border-start-end-radius: 8px;
  border-end-end-radius: 8px;
}

.CalendarDay__day-number {
  text-align: center;
}

.CalendarDay--today {
  font-weight: 600;
}

.CalendarDay--today .CalendarDay__day-number::after {
  content: '';
  border-block-end: 2px solid var(--vkui--color_stroke_accent);
  inline-size: 16px;
  display: block;
  position: relative;
  inset-inline-start: 50%;
  transform: translateX(-50%);
}

.CalendarDay--today .CalendarDay__inner--active .CalendarDay__day-number::after {
  border-block-end-color: var(--vkui--color_stroke_contrast);
}

.CalendarDay__inner,
.CalendarDay__hinted {
  display: flex;
  align-items: center;
  justify-content: center;
  block-size: 100%;
  inline-size: 100%;
}

.CalendarDay__inner {
  border-radius: 8px;
}

.CalendarDay__hinted--active,
.CalendarDay--hover :not(.CalendarDay__hinted--active) .CalendarDay__inner {
  background-color: var(--vkui--color_background_secondary_alpha--hover);
}

.CalendarDay__inner--active {
  background-color: var(--vkui--color_background_accent);
  color: var(--vkui--color_text_contrast);
}
