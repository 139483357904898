.Epic {
  inline-size: 100%;
  block-size: 100%;
}

/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
:global(.vkuiInternalEpic--hasTabbar).Epic {
  --vkui_internal--snackbar_safe_area_inset_bottom: calc(
    var(--vkui_internal--tabbar_height) + var(--vkui_internal--safe_area_inset_bottom)
  );
}
