.Separator--mode-primary {
  color: var(--vkui--color_separator_primary);
}

.Separator--mode-secondary {
  color: var(--vkui--color_separator_secondary);
}

.Separator--mode-primaryAlpha {
  color: var(--vkui--color_separator_primary_alpha);
}

.Separator__in {
  block-size: var(--vkui--size_border--regular);
  margin: 0;
  background: currentColor;
  color: inherit;
  border: 0;
  transform-origin: center top;
}

.Separator--padded .Separator__in {
  margin-inline: var(--vkui--size_base_padding_horizontal--regular);
}

/*
 * CMP:
 * ModalPage
 */
/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
:global(.vkuiInternalModalPage--sizeX-regular) .Separator--padded {
  padding-inline: 8px;
}

/* FIXME: Мертвый код */
@media (--sizeX-regular) {
  /* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
  :global(.vkuiInternalModalPage--sizeX-none) .Separator--padded {
    padding-inline: 8px;
  }
}
