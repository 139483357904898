.Image {
  border-radius: var(--vkui_internal--Image_border_radius, initial);
}

.Image--borderStartStartRadius {
  border-start-start-radius: var(--vkui_internal--Image_border_start_start_radius, initial);
}

.Image--borderStartEndRadius {
  border-start-end-radius: var(--vkui_internal--Image_border_start_end_radius, initial);
}

.Image--borderEndStartRadius {
  border-end-start-radius: var(--vkui_internal--Image_border_end_start_radius, initial);
}

.Image--borderEndEndRadius {
  border-end-end-radius: var(--vkui_internal--Image_border_end_end_radius, initial);
}
