.UnstyledTextField {
  margin: 0;
  border: 0;
  box-shadow: none;
  background: transparent;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  resize: none;
}

.UnstyledTextField--noPadding {
  padding: 0;
}

.UnstyledTextField:focus {
  outline: none;
}

/*
 * Отключаем нативные элементы для <input type="number" />
 *
 * см. https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
 */
.UnstyledTextField::-webkit-outer-spin-button,
.UnstyledTextField::-webkit-inner-spin-button {
  appearance: none;
}

.UnstyledTextField[type='number'] {
  appearance: textfield;
}
