/*
Утилитарные классы, реализующие адаптив через CSS путём показа/скрытия элемента.

Глоссарий по модификаторам:

- `forced` – означает, что мы зашили конкретное представление в `AdaptiveProvider` (ex, `<AdaptiveProvider sizeX="regular">`).
- `mq` – означает, что для смены представления используется CSS Media Query.

---

Note: В начале классов разделить (`-`), чтобы красиво добавлялся префикс библиотеки после сборки.

*/

.-sizeX--compact-forced,
.-sizeX--regular-forced {
  /* Пустой класс для CSS Modules (см. CONTRIBUTING.md)  */
}

@media (--sizeX-regular) {
  .-sizeX--compact-mq {
    /* stylelint-disable-next-line declaration-no-important */
    display: none !important;
  }
}
@media (--sizeX-compact) {
  .-sizeX--regular-mq {
    /* stylelint-disable-next-line declaration-no-important */
    display: none !important;
  }
}

/* ================================================================================================================== */

.-sizeY--compact-forced,
.-sizeY--regular-forced {
  /* Пустой класс для CSS Modules (см. CONTRIBUTING.md)  */
}

@media (--sizeY-regular) {
  .-sizeY--compact-mq {
    /* stylelint-disable-next-line declaration-no-important */
    display: none !important;
  }
}
@media (--sizeY-compact) {
  .-sizeY--regular-mq {
    /* stylelint-disable-next-line declaration-no-important */
    display: none !important;
  }
}

/* ================================================================================================================== */

.-viewWidth--tabletPlus-forced,
.-viewWidth--tabletMinus-forced {
  /* Пустой класс для CSS Modules (см. CONTRIBUTING.md)  */
}

@media (--viewWidth-tabletPlus) {
  .-viewWidth--tabletMinus-mq {
    /* stylelint-disable-next-line declaration-no-important */
    display: none !important;
  }
}
@media (--viewWidth-tabletMinus) {
  .-viewWidth--tabletPlus-mq {
    /* stylelint-disable-next-line declaration-no-important */
    display: none !important;
  }
}

/* ================================================================================================================== */

.-deviceType--mobile-forced,
.-deviceType--desktop-forced {
  /* Пустой класс для CSS Modules (см. CONTRIBUTING.md)  */
}

@media (--desktop) {
  .-deviceType--mobile-mq {
    /* stylelint-disable-next-line declaration-no-important */
    display: none !important;
  }
}
@media (--mobile) {
  .-deviceType--desktop-mq {
    /* stylelint-disable-next-line declaration-no-important */
    display: none !important;
  }
}
