.Pagination__list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.Pagination__prevButtonContainer {
  margin-inline-end: 8px;
}

.Pagination__nextButtonContainer {
  margin-inline-start: 8px;
}
