.SplitCol {
  block-size: 100%;
  inline-size: 1px;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
}

.SplitCol--viewWidth-smallTabletPlus.SplitCol--spaced-auto {
  margin-block: 0;
  margin-inline: var(--vkui--size_split_col_padding_horizontal--regular);
}

@media (--viewWidth-smallTabletPlus) {
  .SplitCol--viewWidth-none.SplitCol--spaced-auto {
    margin-block: 0;
    margin-inline: var(--vkui--size_split_col_padding_horizontal--regular);
  }
}

.SplitCol--viewWidth-tabletMinus.SplitCol--stretched-on-mobile {
  /* stylelint-disable-next-line declaration-no-important */
  inline-size: 100% !important;
  /* stylelint-disable-next-line declaration-no-important */
  max-inline-size: 100% !important;
}

@media (--viewWidth-tabletMinus) {
  .SplitCol--viewWidth-none.SplitCol--stretched-on-mobile {
    /* stylelint-disable-next-line declaration-no-important */
    inline-size: 100% !important;
    /* stylelint-disable-next-line declaration-no-important */
    max-inline-size: 100% !important;
  }
}

.SplitCol--fixed {
  block-size: 100%;
  inline-size: 1px;
  flex-grow: 1;
  flex-shrink: 1;
}

.SplitCol__fixedInner {
  block-size: inherit;
  inline-size: inherit;
  min-inline-size: inherit;
  max-inline-size: inherit;
  position: fixed;
  transform: translateZ(0);
  overflow: hidden;
  inset-block-start: 0;
}
