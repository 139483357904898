.SliderThumb {
  position: absolute;
  z-index: 1;
  box-sizing: border-box;
  inline-size: var(--vkui_internal--slider_thumb_size);
  block-size: var(--vkui_internal--slider_thumb_size);
  border-radius: 50%;
  border: var(--vkui--size_border--regular) solid var(--vkui--color_separator_primary_alpha);
  background-color: var(--vkui--color_background_contrast);
  box-shadow: var(--vkui--elevation3);
  user-select: none;
  transition: background-color 0.15s ease-out;
}

.SliderThumb--focus-visible {
  outline: var(--vkui_internal--outline);
  outline-offset: calc(-1 * var(--vkui--size_border--regular));
}

.SliderThumb--hover {
  background-color: var(--vkui--color_background_contrast--hover);
}

.SliderThumb--active {
  background-color: var(--vkui--color_background_contrast--active);
}

/**
 * Задаём ширину и высоту родителя, чтобы скринридер правильно выделял элемент (скопировано с MUI).
 */
.SliderThumb__nativeInput {
  direction: ltr;
  position: absolute;
  inline-size: 100%;
  block-size: 100%;
  margin: -1px;
  padding: 0;
  border: 0 none;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  white-space: nowrap;
}
