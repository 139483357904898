/**
 * CMP:
 * RadioGroup
 */
/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
:global(.vkuiInternalRadioGroup) .Radio {
  flex: 1;
  margin: 0;
  box-sizing: border-box;
}
