.RootComponent[hidden='hidden'],
.RootComponent--hidden {
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important;
}

.RootComponent[hidden='until-found'] {
  /* stylelint-disable-next-line declaration-no-important */
  content-visibility: hidden !important;
}
