.CalendarRange {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  inline-size: 610px;
  background: var(--vkui--color_background_contrast_themed);
  border: 0.5px solid var(--vkui--color_separator_primary);
  box-sizing: border-box;
  box-shadow: var(--vkui--elevation1);
  border-radius: 8px;
  padding: 12px;
}

.CalendarRange__inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.CalendarRange__inner:not(:last-child) {
  margin-inline-end: 24px;
}

.CalendarRange__header {
  margin-block-end: 4px;
}
