.CustomSelectDropdown {
  overflow: hidden;
  background-color: var(--vkui--color_background_contrast_themed);
  box-sizing: border-box;
  border: var(--vkui--size_border--regular) solid var(--vkui--color_field_border_alpha);
  box-shadow: var(--vkui--elevation1);
  border-radius: 8px;
}

.CustomSelectDropdown--wide {
  inline-size: 100%;
}

.CustomSelectDropdown--bottom {
  border-block-start: 0;
  border-start-start-radius: 0;
  border-start-end-radius: 0;
}

.CustomSelectDropdown--top {
  inset-block-end: 100%;
  border-block-end: 0;
  border-end-start-radius: 0;
  border-end-end-radius: 0;
  box-shadow: var(--vkui--elevation1_invert_y);
}

.CustomSelectDropdown__fetching {
  padding-block: 12px;
  padding-inline: 0;
}

.CustomSelectDropdown__in--withMaxHeight {
  max-block-size: 160px;
}

/**
 * CMP:
 * CalendarHeader
 */
/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
:global(.vkuiInternalCalendarHeader__pickers) .CustomSelectDropdown__in--withMaxHeight {
  max-block-size: 184px;
}
