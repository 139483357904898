.Placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  box-sizing: border-box;
}

.Placeholder--withPadding {
  padding-block: 48px;
  padding-inline: 32px;
}

.Placeholder--stretched {
  flex: 1 0;
  padding-block: inherit;
  block-size: 100%;
}

.Placeholder__icon {
  margin-block-end: 12px;
  display: inline-block;
  vertical-align: top;
  color: var(--vkui--color_icon_secondary);
}

.Placeholder__header {
  color: var(--vkui--color_text_primary);
}

.Placeholder__text {
  color: var(--vkui--color_text_secondary);
}

.Placeholder__header + .Placeholder__text {
  margin-block-start: 8px;
}

.Placeholder__action:not(:first-child) {
  margin-block-start: 16px;
}
