.PanelHeaderContext {
  --vkui_internal--PanelHeaderContext__fade_display: none;

  z-index: var(--vkui_internal--z_index_panel_header_context);
  inline-size: 100%;
  block-size: auto;
}

.PanelHeaderContext--sizeX-compact {
  --vkui_internal--PanelHeaderContext__fade_display: block;

  block-size: 100%;
}

@media (--sizeX-compact) {
  .PanelHeaderContext--sizeX-none {
    --vkui_internal--PanelHeaderContext__fade_display: block;

    block-size: 100%;
  }
}

.PanelHeaderContext__in {
  position: absolute;
  z-index: 1;
  inset-inline-start: 0;
  inline-size: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.PanelHeaderContext__fade {
  display: var(--vkui_internal--PanelHeaderContext__fade_display);
  position: absolute;
  z-index: 0;
  inline-size: 100%;
  block-size: 100%;
  inset-inline-start: 0;
  inset-block-start: 0;
  background: rgba(0, 0, 0, 0.4);
}

.PanelHeaderContext--opened .PanelHeaderContext__fade {
  animation: animation-panelheadercontext-fade-in var(--vkui--animation_duration_m) ease both;
}

.PanelHeaderContext--closing .PanelHeaderContext__fade {
  animation: animation-panelheadercontext-fade-out var(--vkui--animation_duration_m) ease both;
}

.PanelHeaderContext--opened .PanelHeaderContext__in {
  animation: animation-panelheadercontext-translate-in var(--vkui--animation_duration_m) ease both;
}

.PanelHeaderContext--closing .PanelHeaderContext__in {
  animation: animation-panelheadercontext-translate-out var(--vkui--animation_duration_m) ease both;
}

@media (--reduce-motion) {
  .PanelHeaderContext--opened .PanelHeaderContext__in {
    animation-name: animation-panelheadercontext-fade-in;
  }

  .PanelHeaderContext--closing .PanelHeaderContext__in {
    animation-name: animation-panelheadercontext-fade-out;
  }
}

.PanelHeaderContext__content {
  overflow: hidden;
  background: var(--vkui--color_background_content);
}

.PanelHeaderContext--ios .PanelHeaderContext__in {
  padding: 0;
}

/**
 * Имеется ввиду
 * platform !== 'ios' ||
 * (platform === 'ios' && isDesktop)
 */
.PanelHeaderContext:not(.PanelHeaderContext--ios) .PanelHeaderContext__content,
.PanelHeaderContext--sizeX-regular.PanelHeaderContext--ios .PanelHeaderContext__content {
  border-radius: 12px;
  padding-block: 8px;
  padding-inline: 0;
}

@media (--sizeX-regular) {
  .PanelHeaderContext--sizeX-none.PanelHeaderContext--ios .PanelHeaderContext__content {
    border-radius: 12px;
    padding-block: 8px;
    padding-inline: 0;
  }
}

/*
 Desktop
 */

.PanelHeaderContext--sizeX-regular .PanelHeaderContext__in {
  padding-block: 12px;
  padding-inline: 8px;
}

.PanelHeaderContext--sizeX-regular .PanelHeaderContext__content {
  border-radius: 12px;
}

.PanelHeaderContext--sizeX-regular .PanelHeaderContext__content {
  box-shadow:
    0 0 4px rgba(0, 0, 0, 0.08),
    0 8px 8px rgba(0, 0, 0, 0.16);
}

@media (--sizeX-regular) {
  .PanelHeaderContext--sizeX-none .PanelHeaderContext__in {
    padding-block: 12px;
    padding-inline: 8px;
  }

  .PanelHeaderContext--sizeX-none .PanelHeaderContext__content {
    border-radius: 12px;
  }

  .PanelHeaderContext--sizeX-none .PanelHeaderContext__content {
    box-shadow:
      0 0 4px rgba(0, 0, 0, 0.08),
      0 8px 8px rgba(0, 0, 0, 0.16);
  }
}

/*
  Animations
 */

@keyframes animation-panelheadercontext-translate-in {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}
@keyframes animation-panelheadercontext-translate-out {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}
@keyframes animation-panelheadercontext-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes animation-panelheadercontext-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
