.ImageBaseOverlay {
  position: absolute;
  z-index: var(--vkui_internal--z_index_image_base_overlay);
  inset-block-start: 0;
  inset-inline-start: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  inline-size: 100%;
  block-size: 100%;
  border: 0;
  border-radius: inherit;
  box-shadow: inherit;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.ImageBaseOverlay--visible {
  opacity: 1;
}

@media (--hover-has) {
  .ImageBaseOverlay:hover {
    opacity: 1;
  }
}

.ImageBaseOverlay--clickable {
  cursor: pointer;
}

.ImageBaseOverlay--theme-light {
  color: var(--vkui--color_icon_accent);
  background-color: var(--vkui--color_avatar_overlay_inverse_alpha);
}

.ImageBaseOverlay--theme-dark {
  color: var(--vkui--color_icon_contrast);
  background-color: var(--vkui--color_avatar_overlay);
}
