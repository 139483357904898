.CalendarTime {
  display: flex;
  align-items: center;
}

.CalendarTime__picker {
  inline-size: 77px;
}

.CalendarTime__divider {
  margin-inline: 6px;
  color: var(--vkui--color_text_primary);
  font-family: var(--vkui--font_family_base);
}

.CalendarTime__button {
  margin-inline-start: auto;
}
