.Spacing {
  --vkui_internal--Spacing_gap: 0;

  position: relative;
  padding-block: calc(var(--vkui_internal--Spacing_gap) / 2);
  box-sizing: border-box;
}

.Spacing--3xs {
  /* TODO [>=7]: удалить deprecated токен */
  --vkui_internal--Spacing_gap: var(--vkui--spacing_size_3xs);
}

.Spacing--2xs {
  --vkui_internal--Spacing_gap: var(--vkui--spacing_size_2xs);
}

.Spacing--xs {
  --vkui_internal--Spacing_gap: var(--vkui--spacing_size_xs);
}

.Spacing--s {
  --vkui_internal--Spacing_gap: var(--vkui--spacing_size_s);
}

.Spacing--m {
  --vkui_internal--Spacing_gap: var(--vkui--spacing_size_m);
}

.Spacing--l {
  --vkui_internal--Spacing_gap: var(--vkui--spacing_size_l);
}

.Spacing--xl {
  --vkui_internal--Spacing_gap: var(--vkui--spacing_size_xl);
}

.Spacing--2xl {
  --vkui_internal--Spacing_gap: var(--vkui--spacing_size_2xl);
}

.Spacing--3xl {
  --vkui_internal--Spacing_gap: var(--vkui--spacing_size_3xl);
}

.Spacing--4xl {
  --vkui_internal--Spacing_gap: var(--vkui--spacing_size_4xl);
}
