.FloatingArrow {
  position: absolute;
}

.FloatingArrow__in {
  content: '';
  display: block;

  /* см. Примечание 1 в FloatingArrow.tsx. */
  transform: translateY(1px);
}

.FloatingArrow--placement-right {
  transform: rotate(90deg) translate(50%, -50%);
  transform-origin: right;
}

.FloatingArrow--placement-bottom {
  transform: rotate(180deg);
}

.FloatingArrow--placement-left {
  transform: rotate(-90deg) translate(-50%, -50%);
  transform-origin: left;
}
