.CustomSelect {
  inline-size: 100%;
  position: relative;
  display: block;
  cursor: pointer;
}

.CustomSelect--pop-down {
  border-end-start-radius: 0;
  border-end-end-radius: 0;
}

.CustomSelect--pop-up {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
}

.CustomSelect__control {
  display: none;
}

.CustomSelect__empty {
  padding-block: 12px;
  padding-inline: 0;
  text-align: center;
  color: var(--vkui--color_text_secondary);
}

.CustomSelect__fetching {
  display: flex;
  align-items: center;
  justify-content: center;
}

.CustomSelect__dropdown-icon {
  margin-inline-end: 10px;
}

.CustomSelect--clear-icon {
  margin-inline-end: -6px;
}

.CustomSelect--sizeY-compact .CustomSelect__dropdown-icon {
  margin-inline-end: 8px;
}

.CustomSelect--sizeY-compact .CustomSelect--clear-icon {
  margin-inline-end: -2px;
}

@media (--sizeY-compact) {
  .CustomSelect--sizeY-none .CustomSelect__dropdown-icon {
    margin-inline-end: 8px;
  }

  .CustomSelect--sizeY-none .CustomSelect--clear-icon {
    margin-inline-end: -2px;
  }
}
