.UsersStack {
  display: flex;
  align-items: center;
  color: var(--vkui--color_text_secondary);
}

.UsersStack--direction-row-reverse {
  flex-direction: row-reverse;
}

.UsersStack--direction-column {
  flex-direction: column;
  justify-content: center;
}

.UsersStack__photos {
  display: flex;
}

.UsersStack__fill {
  fill: var(--vkui--color_image_placeholder_alpha);
}

.UsersStack__photo--others {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--vkui--color_text_contrast_themed);
  background: var(--vkui--color_icon_secondary);
  border-radius: 50%;
}

.UsersStack--size-s .UsersStack__photo {
  inline-size: 16px;
  block-size: 16px;
}

.UsersStack--size-m .UsersStack__photo {
  inline-size: 24px;
  block-size: 24px;
}

.UsersStack--size-l .UsersStack__photo {
  inline-size: 32px;
  block-size: 32px;
}

.UsersStack__photoWrapper {
  display: flex;
}

.UsersStack__photoWrapper:not(:first-child) {
  margin-inline-start: -2px;
}

.UsersStack__text {
  min-inline-size: 0;
  flex: 1;
}

.UsersStack--direction-column .UsersStack__text {
  text-align: center;
  margin-block-start: 8px;
}

.UsersStack--direction-row .UsersStack__text {
  margin-inline-start: 8px;
}

.UsersStack--direction-row-reverse .UsersStack__text {
  margin-inline-end: 8px;
}
