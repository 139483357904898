/* Page styles */
.PaginationPage {
  box-sizing: border-box;
  padding-inline: 8px;
  inline-size: 100%;
  min-inline-size: 40px;
  border-radius: var(--vkui--size_border_radius--regular);
  text-align: center;
  color: var(--vkui--color_text_secondary);
  user-select: none;
  block-size: var(--vkui--size_button_large_height--regular);
  line-height: var(--vkui--size_button_large_height--regular);
  display: block;
  text-decoration: none;
}

.PaginationPage--state-hover {
  background-color: var(--vkui--color_transparent--hover);
}

.PaginationPage--state-active,
.PaginationPage--current {
  color: var(--vkui--color_text_primary);
  background-color: var(--vkui--color_transparent--active);
}

.PaginationPage--disabled {
  opacity: var(--vkui--opacity_disable);
}

.PaginationPage--sizeY-compact {
  line-height: var(--vkui--size_button_large_height--compact);
  block-size: var(--vkui--size_button_large_height--compact);
}

@media (--sizeY-compact) {
  .PaginationPage--sizeY-none {
    line-height: var(--vkui--size_button_large_height--compact);
    block-size: var(--vkui--size_button_large_height--compact);
  }
}

.PaginationPage--type-ellipsis {
  min-inline-size: 16px;
  padding-inline: 0;
}
