.CheckboxInput {
  color: var(--vkui--color_icon_accent);
}

.CheckboxInput__icon--on,
.CheckboxInput__icon--indeterminate {
  display: none;
}

.CheckboxInput__icon--off {
  color: var(--vkui--color_icon_tertiary);
}

.CheckboxInput__input:checked ~ .CheckboxInput__icon--on {
  display: flex;
}

.CheckboxInput__input:indeterminate ~ .CheckboxInput__icon--indeterminate,
.CheckboxInput__input:checked ~ .CheckboxInput__icon--off {
  display: none;
}

.CheckboxInput__input:indeterminate ~ .CheckboxInput__icon--indeterminate {
  display: flex;
}

.CheckboxInput__input:indeterminate ~ .CheckboxInput__icon--on,
.CheckboxInput__input:indeterminate ~ .CheckboxInput__icon--off {
  display: none;
}
