.Badge {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  inline-size: 6px;
  block-size: 6px;
  border-radius: 50%;
}

.Badge--mode-new {
  background-color: var(--vkui--color_icon_accent);
}

.Badge--mode-prominent {
  background-color: var(--vkui--color_accent_red);
}

/**
 * CMP:
 * TabbarItem
 */
/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
:global(.vkuiInternalTabbarItem__label) .Badge {
  position: absolute;
  inset-block-start: -2px;
  inset-inline-end: -2px;
}
